<template>
  <div class="mcn-business-info-page">
    <div class="container">
      <div class="item">
        <div class="title">
          <span>权益项</span>
        </div>
        <div class="value">
          <span>权益内容</span>
        </div>
      </div>
      <div class="item">
        <div class="title">
          <span>返点比例</span>
        </div>
        <div class="value">
          <span>{{ getCommissionRate() }}</span>
        </div>
      </div>
      <div class="item">
        <div class="title">
          <span>年框梯度</span>
        </div>
        <div class="value">
          <span>{{ data && data.rebate_gradients ? data.rebate_gradients : '-' }}</span>
        </div>
      </div>
      <div class="item">
        <div class="title">
          <span>特别政策</span>
        </div>
        <div class="value">
          <span>{{ data && data.special_policy ? data.special_policy : '-' }}</span>
        </div>
      </div>
      <div class="item">
        <div class="title">
          <span>结算返点额外<br>扣税</span>
        </div>
        <div class="value">
          <span>{{ getHasRebateTax() }}</span>
        </div>
      </div>
      <div class="item">
        <div class="title">
          <span>梯度拉平</span>
        </div>
        <div class="value">
          <span>{{ getCrossEntropy() }}</span>
        </div>
      </div>
      <div class="item">
        <div class="title">
          <span>签署年框</span>
        </div>
        <div class="value">
          <span>{{ getSignYearFrame() }}</span>
        </div>
      </div>
      <div class="item">
        <div class="title">
          <span>返点结算周期</span>
        </div>
        <div class="value">
          <span>{{ getRebateSettlementPeriod() }}</span>
        </div>
      </div>
      <div class="item">
        <div class="title">
          <span>返点结算方式</span>
        </div>
        <div class="value">
          <span>{{ getRebateSettlementPayment() }}</span>
        </div>
      </div>
      <div class="item">
        <div class="title">
          <span>备注</span>
        </div>
        <div class="value">
          <span>{{ data.remark || '-' }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'McnBusinessInfo',
  props: {
    data: {
      type: Object,
      default() {
        return null;
      }
    }
  },
  methods: {
    getCommissionRate() {
      if (this.data.rebate_rate === null) {
        return '-';
      }
      return this.$convertUnit(this.data.rebate_rate, 100, 0) + '%';
    },
    getHasRebateTax() {
      if (this.data.has_rebate_tax === null) {
        return '暂无';
      } else if (this.data.has_rebate_tax) {
        return '是';
      } else {
        return '否';
      }
    },
    getCrossEntropy() {
      if (this.data.cross_entropy === null) {
        return '暂无';
      } else if (this.data.cross_entropy) {
        return '是';
      } else {
        return '否';
      }
    },
    getSignYearFrame() {
      if (this.data.sign_year_frame === null) {
        return '暂无';
      } else if (this.data.sign_year_frame) {
        return '是';
      } else {
        return '否';
      }
    },
    getRebateSettlementPeriod() {
      if (this.data.rebate_settlement_period === 1) {
        return '月度';
      } else if (this.data.rebate_settlement_period === 1) {
        return '季度';
      } else {
        return '暂无';
      }
    },
    getRebateSettlementPayment() {
      if (this.data.rebate_settlement_period === 1) {
        return '对公';
      } else if (this.data.rebate_settlement_period === 1) {
        return '对私';
      } else {
        return '暂无';
      }
    },
  },
};
</script>

<style lang="less" scoped>
.mcn-business-info-page {
  padding: 40px 30px;
  background: #fff;

  .container {
    border-radius: 8px;
    border: 1px solid #E6E6E6;

    .item {
      display: flex;
      font-size: 28px;
      font-weight: 400;
      color: #333;
      line-height: 34px;

      &:first-child {
        background: #F7F7F7;
        padding: 36px 30px;
        border-bottom: 1px solid #E6E6E6;
      }

      &:nth-child(n+2) {
        margin: 0 20px;
        padding: 36px 10px;
        border-bottom: 1px solid #E0E0E0;
      }

      &:last-child {
        border: none;
      }

      .title {
        width: 270px;
        flex-shrink: 0;
      }

      .value {
        white-space: pre-line;
      }
    }
  }
}
</style>