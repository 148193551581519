<template>
  <div>
    <mcn :data="data" />
  </div>
</template>

<script>
import Mcn from '@/views/establish/Mcn';

export default {
  name: 'Detail',
  components: { Mcn },
  data() {
    return {
      mcnId: this.$route.params.id,
      data: {
        id: this.$route.params.id,
      }
    };
  },
};
</script>

<style lang="less" scoped>

</style>