<template>
  <div
    id="mcn-page"
    class="mcn-page"
  >
    <div class="mcn-info">
      <img
        class="avatar"
        src="@/assets/images/icon-mcn@2x.png"
      >
      <div class="name">
        <span>{{ detail && detail.name ? detail.name : '-' }}</span>
      </div>
      <van-button
        class="btn-changed"
        type="primary"
        @click="changeBounds"
        v-if="session"
      >
        切换
      </van-button>
    </div>

    <div class="tab_container">
      <tab
        :items="tabItems"
        @change="handleTabChanged"
      />
    </div>

    <mcn-business-info
      :data="detail"
      v-if="tabActiveIndex === 0"
    />
    <bound-sessions
      :sessions="sessions"
      v-else-if="tabActiveIndex === 1"
    />

    <div
      class="bottom-placeholder"
    />
    <div
      class="bottom"
      :style="'width:'+bottomWidth+'px;'"
    >
      <van-button
        class="btn-add-bind"
        @click="toAddBind"
        :class="!hasPermission ? 'full':''"
        v-if="session"
      >
        新增绑定
      </van-button>
      <van-button
        class="btn-back"
        :class="!hasPermission ? 'full':''"
        @click="back"
        v-else
      >
        返回上一级
      </van-button>
      <van-button
        class="btn-edit-business-info"
        @click="goToEditMcn"
        v-if="hasPermission"
      >
        录入权益
      </van-button>
    </div>

    <van-action-sheet
      v-model:show="showAddBindType"
      :actions="actions"
      cancel-text="取消"
      close-on-click-action
      @select="handleSelectAddBind"
    />
  </div>
</template>

<script>
import Tab from '@/components/Tab';
import { mapActions } from 'vuex';
import { Toast } from 'vant';
import McnBusinessInfo from '@/views/establish/McnBusinessInfo';
import BoundSessions from '@/views/establish/BoundSessions';

export default {
  name: 'Mcn',
  components: {
    BoundSessions,
    McnBusinessInfo,
    Tab,
  },
  props: {
    session: {
      type: Object,
      default() {
        return null;
      },
    },
    data: {
      type: Object,
      default() {
        return null;
      },
    },
  },
  data() {
    return {
      bottomWidth: 0,
      detail: undefined,
      sessions: [],
      hasPermission: false,
      showAddBindType: false,
      actions: [
        { name: '绑定达人' },
        { name: '绑定MCN机构' },
      ],
      tabItems: [
        '机构权益',
        '客户/群聊',
      ],
      tabActiveIndex: 0,
      user: undefined,
    };
  },
  created() {
    this.toGetMcnDetail();
    this.toGetLoginUser();
    this.toGetTiktokStarBound();
    this.$nextTick(function () {
      const page_el = document.getElementById('mcn-page');
      if (page_el) {
        this.bottomWidth = page_el.offsetWidth;
      }
    });
  },
  methods: {
    back() {
      this.$router.back();
    },
    toAddBind() {
      this.showAddBindType = true;
    },
    handleTabChanged(item, index) {
      this.tabActiveIndex = index;
    },
    handleSelectAddBind(action, index) {
      if (!this.session || !this.session.id) {
        Toast.fail({
          message: '会话数据异常,请刷新后重试',
        });
        return;
      }
      if (index === 0) {
        this.$router.push({
          name: 'BindingSessionStar',
          params: {
            id: this.session.id,
          },
        });
      } else if (index === 1) {
        this.$router.push({
          name: 'BindingSessionMcn',
          params: {
            id: this.session.id,
          },
        });
      }
    },
    goToEditMcn() {
      this.$router.push({
        name: 'EditMcnBusinessInfo',
        params: {
          id: this.data.id,
        },
      });
    },
    async toGetMcnDetail() {
      if (!this.data || !this.data.id) {
        return;
      }
      this.detail = this.data;

      const res = await this.getMcnDetail({ id: this.data.id });
      if (!res) {
        return;
      }
      this.detail = res;
    },
    changeBounds() {
      this.$emit('changeBounds');
    },
    async toGetLoginUser() {
      const res = await this.getLoginUser();
      if (!res) {
        return;
      }
      this.user = res;
      this.hasPermission = this.user.permission && this.user.permission.mcn_edit;
    },
    ...mapActions({
      getMcnDetail:
        'star/getMcnDetail',
      getLoginUser:
        'user/getLoginUser',
      getMcnBound:
        'session/getMcnBound',
    }),
    async toGetTiktokStarBound() {
      if (!this.data || !this.data.id) {
        return;
      }
      this.detail = this.data;

      const res = await this.getMcnBound({ mcnId: this.data.id });
      if (!res) {
        return;
      }
      this.sessions = res;
    },
  }
};
</script>

<style lang="less" scoped>
.mcn-page {
  background: #fff;

  .mcn-info {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 30px;

    .avatar {
      flex-shrink: 0;
      width: 108px;
      height: 108px;
      border-radius: 60px;
    }

    .name {
      width: 100%;
      font-size: 32px;
      font-weight: 600;
      color: #333333;
      line-height: 32px;
      margin-left: 30px;
      overflow: hidden;
    }

    .btn-changed {
      flex-shrink: 0;
      background: #0095FF;
      border-radius: 27px;
      width: 120px;
      height: 54px;
      font-size: 26px;
      font-weight: 600;
      color: #FFFFFF;
      line-height: 26px;
    }
  }

  .tab_container {
    padding-left: 30px;
    border-bottom: 1px solid #E0E0E0;
  }

  .bottom-placeholder {
    height: 160px;
  }

  .bottom {
    display: flex;
    align-items: center;
    background: #fff;
    height: 160px;
    border-top: 1px solid #E6E6E6;
    position: fixed;
    bottom: 0;
    .btn-back,
    .btn-add-bind {
      width: 36%;
      background: #FFFFFF;
      border-radius: 40px;
      border: 1px solid #0095FF;
      font-size: 30px;
      font-weight: 600;
      color: #0095FF;
      line-height: 30px;
      margin-left: 30px;

      &:active {
        opacity: 0.8;
      }
    }

    .full {
      width: 100%;
      margin-right: 30px;
    }

    .btn-edit-business-info {
      width: 64%;
      background: #0095FF;
      border-radius: 40px;
      font-size: 30px;
      font-weight: 600;
      color: #FFFFFF;
      line-height: 30px;
      margin-left: 10px;
      margin-right: 30px;

      &:active {
        opacity: 0.8;
      }
    }
  }
}
</style>